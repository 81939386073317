import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
import Talk from "../components/mixin-collage/talk";
import Tweet from "../components/mixin-collage/tweet";
import Image from "../components/mixin-collage/image";
import Collage from "../components/mixin-collage/collage.module.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Metatags title="Mixin Collage | May 8 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-8.png" url="https://cssgrid31.brett.cool" pathname="/may-8" mdxType="Metatags" />
    <div className={Collage.grid}>
  <Tweet quote="Had THE best time at [@mixinconf](https://twitter.com/mixinconf)! Walked away feeling so inspired and hoping to make a difference in the internets. [#mixin16](https://twitter.com/search?q=%23mixin16)" account="jessicatron" id={791950347912613888} mdxType="Tweet" />
  <Talk filename="andy" speaker="Andy Clarke" title="Art Directing the Web" link="https://www.youtube.com/watch?v=m-7VzdeMyiU" mdxType="Talk" />
  <Image filename="swag" description="Mixin tees" mdxType="Image" />
  
  <Image filename="organisers" description="Patima and Mandy" mdxType="Image" />
  <Image filename="crowd" description="Mixin 2016 Crowd" size="responsive" mdxType="Image" />
  <Tweet quote="Completely blown away by the incredible talks this morning at [#mixin16](https://twitter.com/search?q=%23mixin16)! So much passion! [@mixinconf](https://twitter.com/mixinconf) [@Malarkey](https://twitter.com/Malarkey) [@Una](https://twitter.com/Una) [@byalicelee](https://twitter.com/byalicelee) [@notdetails](https://twitter.com/notdetails)" account="Jmilr" id={791902010157084672} mdxType="Tweet" />
  <Image filename="rottnest" description="Rottnest" size="large" mdxType="Image" />
  <Talk filename="jina" speaker="Jina Anne" title="Designing a Design System" link="https://www.youtube.com/watch?v=iJOJeYg1swQ" mdxType="Talk" />
  <Image filename="breakout" description="Breakout area" mdxType="Image" />
  <Image filename="bunting" description="Mixin bunting" mdxType="Image" />
  <Image filename="lunch" description="Lunch packs" size="responsive" mdxType="Image" />
  <Tweet quote="Congrats to [@mixinconf](https://twitter.com/mixinconf) for naaaaailing their debut ✨✨✨ [#mixin16](https://twitter.com/search?q=%23mixin16)" account="iamthewong" id={791951833207930880} mdxType="Tweet" />
  <Tweet quote="This conference was full of so much love!! 💕 huge thank u to the incredible [@mixinconf](https://twitter.com/mixinconf) organizers who made this week unforgettable ✨[#mixin16](https://twitter.com/search?q=%23mixin16)" account="una" id={791935016716546048} mdxType="Tweet" />
  <Image filename="una_kylie" description="Una and Kylie" size="responsive" mdxType="Image" />
  <Talk filename="alice" speaker="Alice Lee" title="Pencil Mileage" link="https://www.youtube.com/watch?v=wnej_5GKqUI" mdxType="Talk" />
  <Image filename="collide" description="Collide speaking event" mdxType="Image" />
  <Image filename="outside" description="Mixin volunteers, organisers, and attendees" mdxType="Image" />
  <Tweet quote="Absolutely brilliant day at the Mixin Conference [#mixin16](https://twitter.com/search?q=%23mixin16)! Thank you so much for errything [@mixinconf](https://twitter.com/mixinconf) !!" account="Gennner" id={791930455838773249} mdxType="Tweet" />
  <Tweet quote="I'm so crazy lucky to know so many of the Perth web community. Feels like a family. So much psych for today [#mixin16](https://twitter.com/search?q=%23mixin16)" account="ThomasLeenders" id={791791788767911936} mdxType="Tweet" />
  <Talk filename="una" speaker="Una Kravets" title="The Joys of Optimising" link="https://www.youtube.com/watch?v=3j6aISzkbS4" mdxType="Talk" />
  <Image filename="breakout_alt" description="Coffee break" mdxType="Image" />
  <Image filename="quokkas" description="Una, Joel, and a couple of Quokkas" mdxType="Image" />
  <Tweet quote="So much love for [#mixin16](https://twitter.com/search?q=%23mixin16) - feel so inspired. Back to work with fresh ideas next week!" account="FlorMostaccio" id={791970458077761537} mdxType="Tweet" />
  <Image filename="una_sketch" description="Sketch of the Mixin organisers by Una Kravets" size="responsive" mdxType="Image" />
  <Tweet quote="Total noobs, responsive tomatoes, Bob Ross, Aussie animal puns, unicorns, art direction, comedy & good company. Much inspiration @ [#mixin16](https://twitter.com/search?q=%23mixin16)" account="stringy" id={791932809485049856} mdxType="Tweet" />
  <Image filename="close" description="After the day" size="responsive" mdxType="Image" />
  <Talk filename="joel" speaker="Joel Califa" title="Full Stack Anxiety" link="https://www.youtube.com/watch?v=VBK6WDOOg2I" mdxType="Talk" />
  <Image filename="team" description="Mixin Organisers" mdxType="Image" />
  <Image filename="sign" description="Mixin 2016 sign" mdxType="Image" />
  <Image filename="joel" description="Joel Selfie" size="large" mdxType="Image" />
  <Tweet quote="All time conference! [#goosebumps](https://twitter.com/search?q=%23goosebumps) thanks guys [#mixin16](https://twitter.com/search?q=%23mixin16) [@mixinconf](https://twitter.com/mixinconf)" account="FinelySliced" id={791940690968125440} mdxType="Tweet" />
  <Image filename="booth_collage" description="Mixin afterparty photobooth" mdxType="Image" />
  <Image filename="booth" description="Photobooth at the Mixin 2016 afterparty" mdxType="Image" />
  <Image filename="tote" description="Mixin Tote" size="responsive" mdxType="Image" />
  <Tweet quote="Amazing, inspiring day at [#mixin16](https://twitter.com/search?q=%23mixin16). Wonderful minds hosted by strong friends.  Loved every second, [@mixinconf](https://twitter.com/mixinconf)!" account="vSanjo" id={791942975949451264} mdxType="Tweet" />
  <Image filename="crowd_closeup" description="Mixin attendees" size="responsive" mdxType="Image" />
  <Tweet quote="if there's another [@mixinconf](https://twitter.com/mixinconf) in Perth next year and you don't apply, you're doing yourself a disservice. In awe of these people." account="notdetails" id={792077897057959936} mdxType="Tweet" />
  <Talk filename="mike" speaker="Mike Riethmuller" title="A CSS Eulogy" link="https://www.youtube.com/watch?v=SwWiJ-0Ztmg" mdxType="Talk" />
  <Image filename="crowd_alt" description="Mixin crowd" mdxType="Image" />
  <Image filename="patima_afterparty" description="Patima at the after party" mdxType="Image" />
  <Image filename="stage" description="Mixin stage" size="responsive" mdxType="Image" />
  <Tweet quote="Veeery impressed with the [#mixin16](https://twitter.com/search?q=%23mixin16) conference on so many levels. You nailed it guys. Awesome talks, flawless organisation!" account="phildelalande" id={791938776591314944} mdxType="Tweet" />
  <Image filename="collide_crowd" description="Collide audience" size="responsive" mdxType="Image" />
  <Image filename="photo_booth" description="After party photobooth" size="large" mdxType="Image" />
  <Image filename="hoops" description="Hula hoops at the after party" size="responsive" mdxType="Image" />
  <Talk filename="espen" speaker="Espen Brunborg" title="The Secret Life of Comedy" link="https://www.youtube.com/watch?v=NzfQvPUk-dE" mdxType="Talk" />
  <Tweet quote="Reading back over my notes from [@mixinconf](https://twitter.com/mixinconf) yesterday, feeling so freakin inspired! Legend organisers legend speakers. Ripper day [#mixin16](https://twitter.com/search?q=%23mixin16)" account="ddmoore" id={792210153349054464} mdxType="Tweet" />
  <Image filename="caversham" description="Speakers and organisers at Caversham Wildlife Park" size="responsive" mdxType="Image" />
  <Tweet quote="Reading back through the [#mixin16](https://twitter.com/search?q=%23mixin16) feed and getting the feels again. What an event!" account="EliFerrall" id={792261192240824320} mdxType="Tweet" />
  <Image filename="attendees" description="Attendees at the after party" mdxType="Image" />
  <Image filename="stage_high" description="View from the stage" mdxType="Image" />
  <Image filename="lee" description="Lee at the photobooth" mdxType="Image" />
  <Image filename="teegan_dt" description="Teegan and DT" mdxType="Image" />
  <Tweet quote="Congrats to the [@mixinconf](https://twitter.com/mixinconf) crew for putting on a fantastic event, looking forward to next year [#mixin16](https://twitter.com/search?q=%23mixin16) [#inspireddesign](https://twitter.com/search?q=%23inspireddesign) [#designsystems](https://twitter.com/search?q=%23designsystems)" account="raynimmo" id={791949218206191616} mdxType="Tweet" />
  <Image filename="workshop" description="Jina’s design systems workshop" size="responsive" mdxType="Image" />
  <Tweet quote="Woo! [@mixinconf](https://twitter.com/mixinconf) was absolutely awesome. So much energy flowing throughout the day! [#mixin16](https://twitter.com/search?q=%23mixin16)
      
  Congrats to the team behind it!" account="Ash_Vadolia" id={791945740691972101} mdxType="Tweet" />
  <Image filename="phuong" description="Mixin organisers and volunteers" size="responsive" mdxType="Image" />
    </div>
    <Footer date={8} prev={true} next={true} mdxType="Footer">
      <p>{`The Mixin `}<a parentName="p" {...{
          "href": "https://mixinconf.com",
          "title": "Mixinconf"
        }}>{`farewell collage`}</a>{`, recreated with CSS Modules and React components.`}</p>
      <p>{`Admittedly, reusing some prior work, with some additional tweaks. I have to give credit for the original design to fellow Mixin organiser, `}<a parentName="p" {...{
          "href": "https://twitter.com/kylietimpani",
          "title": "Kylie Timpani on Twitter"
        }}>{`Kylie Timpani`}</a>{`.`}</p>
      <p>{`This was my first experiment with CSS Grid back in the day, and really introduced me to some of what CSS Grid was really capable of.`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      